import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES } from "../components/seo";
import { translations } from "../locales/companies.js";
import EventsTypes from "../components/eventsTypes";
import companies from "../assets/img/iconAdvantage/company.svg";

const Companies = () => {
  return (
    <Layout page="companies" language="es">
      <main className="  container">
        <EventsTypes
          background={companies}
          text={translations.es.text}
          title={translations.es.title}
          textButton={translations.es.button}
          isBusiness={true}
          language={"es"}
        />
      </main>
    </Layout>
  );
};

export default Companies;

export const Head = () => (
  <SeoES
    title="Invitaciones digitales para Eventos de Empresa | Diseña y envía tus invitaciones con Kolibird"
    description="Con Kolibird, crea y envía tus invitaciones de empresa de manera digital. Personaliza tus diseños y sorprende a tus invitados con una alternativa ecológica y práctica. ¡Regístrate ahora en Kolibird y comienza a diseñar tus invitaciones para Eventos corporativos!"
    hrefLangs={[
      { href: "https://kolibird.app/empresas/", lang: "es" },
      { href: "https://kolibird.app/en/companies/", lang: "en" }
   ]}
  />
);
