export const translations = {
  es: {
    title: "Invitaciones digitales para eventos de empresa",
    text: [
      {
        h2: `¿Te gustaría poder mandar las invitaciones de tu evento de empresa con Kolibird?`,
        paragraph: [
          {
            text: `¡Estamos preparados para ello! Ofrecemos tarifas y `,
          },
          {
            span: `condiciones especiales a empresas, agencias de organización de eventos y wedding planners `,
          },
          {
            text: `para que podáis enviar las invitaciones de vuestro eventos o que podáis ofrecer el uso de nuestra herramienta a vuestros clientes.\n\n`,
          },
          {
            text: `Podréis utilizarla directamente o pedirnos ayuda para gestionar aspectos que van desde el `,
          },
          {
            span: `diseño de invitaciones para eventos `,
          },
          {
            text: `o save the dates hasta la creación de una herramienta personalizada donde vuestra marca o la de vuestros clientes será la protagonista. \n\n `,
          },
          {
            text: `¡Escríbenos y te contactaremos lo antes posible!  `,
          },
          { button: "Contactar" },
        ],
      },
      {
        h2: `Nuestros puntos fuertes:`,
        paragraph: [
          // {
          //   text: `Las `,
          // },
          // {
          //   span: `invitaciones digitales para organizar eventos `,
          // },
          // {
          //   text: `ofrecen numerosas ventajas tanto para los wedding planners como para los invitados.`,
          // },
        ],
      },
      {
        h3: `1. Personalización:`,
        paragraph: [
          {
            text: ` Podrás utilizar una de nuestra plantillas o empezar su diseño de cero. `,
          },
          {
            span: `Podrán incluso subir una foto o diseño propio `,
          },
          {
            text: `y terminar de editarlo en nuestra herramienta.`,
          },
        ],
      },
      {
        h3: `2. Importa tu lista de contactos: `,
        paragraph: [
          {
            text: `Importa rápidamente la lista de contactos a los que quieres enviar tu invitación. Podrás descargarte nuestra plantilla excel y completarla con los mails y nombres de los asistentes. Validaremos que todas las direcciones de email son correctas.`,
          },
          // {
          //   span: `invitaciones digitales para eventos `,
          // },
          // {
          //   text: `ofrecen un amplio abanico de posibilidades en términos de diseño. Los wedding planners y agencias organizadoras de eventos pueden personalizar las invitaciones para reflejar la temática, estilo y colores de la celebración. Además, es posible agregar elementos interactivos como videos o enlaces a sitios web.`,
          // },
        ],
      },
      {
        h3: `3. Save The Date:`,
        paragraph: [
          {
            text: `Podrás enviar, previo a la invitación, un Save The Date para que tus invitados se guarden la fecha de la boda y no lo olviden mientras trabajáis en la organización del evento.`,
          },
          // {
          //   span: `invitaciones digitales para eventos `,
          // },
          // {
          //   text: `ofrecen un amplio abanico de posibilidades en términos de diseño. Los wedding planners y agencias organizadoras de eventos pueden personalizar las invitaciones para reflejar la temática, estilo y colores de la celebración. Además, es posible agregar elementos interactivos como videos o enlaces a sitios web.`,
          // },
        ],
      },
      {
        h3: `4. Trackea`,
        paragraph: [
          {
            text: `Haz el seguimiento `,
          },
          {
            span: `en tiempo real  `,
          },
          {
            text: `de qué asistentes han recibido la invitación, quién la ha abierto, quién ha contestado y quién no y, por supuesto, quién asiste al evento y quién no.`,
          },
        ],
      },
      {
        h3: `5. Encuestas:`,
        paragraph: [
          {
            text: `Podrás realizar preguntas a los asistentes en la propia invitación del tipo: "¿Necesitarás hacer uso de nuestro servicio de autobuses?, ¿tienes alguna alergia o intolerancia?". Reúne toda esta información y descárgala en un documento que permita optimizar tu tiempo`,
          },
          // {
          //   span: `invitaciones digitales para profesionales de Kolibird `,
          // },
          // {
          //   text: `permiten a los wedding planners y agencias obtener datos en tiempo real sobre los invitados que han abierto la invitación, confirmado su asistencia o incluso dejado comentarios. Esto facilita la gestión de la lista de invitados y la planificación de la logística del evento.`,
          // },
        ],
      },
      {
        h3: `6. Recordatorios: `,
        paragraph: [
          {
            text: `Podrás fijar recordatorios  `,
          },
          {
            span: `automáticos `,
          },
          {
            text: `a aquellos invitados que no hayan abierto o no hayan contestado a la invitación.`,
          },
        ],
      },
      {
        h3: `7. Acceso: `,
        paragraph: [
          {
            text: ` Los asistentes podrán acceder al evento por medio de `,
          },
          {
            span: `un QR nominal  `,
          },
          {
            text: `que recibirán previo al evento y que será validado por medio de una para iOS y para Android. \n\n `,
          },
          {
            text: `¡Escríbenos y te contactaremos lo antes posible!`,
          },
          { button: "Contactar" },
        ],
      },
      {
        h3: "¿Quién ha confiado en Kolibird?",
        paragraph: [
          {
            text: "Estos son algunos de los clientes con los que hemos trabajado hasta el momento.",
          },
          { slider: " " },
        ],
      },
      {
        h3: `Invitaciones digitales para weddings planners y agencias organizadoras de eventos`,
        paragraph: [
          {
            text: `En la industria de los eventos, la primera impresión cuenta y las invitaciones juegan un papel crucial para crear expectativas y emociones en los invitados. \n\nCon el avance de la tecnología, las `,
          },
          {
            span: `invitaciones digitales `,
          },
          {
            text: `se han convertido en una alternativa moderna y eficiente para los wedding planners y agencias organizadoras de eventos. \n\n¿Quieres saber cómo Kolibird, a través de una herramienta especializada, puede ayudar a los profesionales del sector a ofrecer este servicio a sus clientes?\n\n
            `,
          },
          { button: "Contactar" },
        ],
      },
      {
        h2: `Preguntas frecuentes (FAQs)`,
        paragraph: [
          {
            span: `¿Cuáles son los costes de utilizar Kolibird como wedding planner o agencia de eventos?\n\n`,
          },
          {
            text: `Los costes de utilizar Kolibird como wedding planner varían según el plan y las necesidades específicas. Te invitamos a ponerte en contacto con nosotros para obtener más información sobre las tarifas y condiciones.`,
          },

          {
            span: `\n\n¿Puedo utilizar mi propia marca en las invitaciones digitales?\n\n`,
          },
          {
            text: `Sí, Kolibird ofrecemos opciones de personalización que te permiten incluir tu propia marca en las invitaciones digitales. Esto ayuda a mantener la coherencia de la identidad visual de tu negocio.`,
          },
          {
            span: `\n\n¿Cómo puedo realizar un seguimiento de las confirmaciones de asistencia?\n\n`,
          },
          {
            text: `Dispones de funciones avanzadas de seguimiento de RSVP. Podrás ver quién ha abierto la invitación, quién ha confirmado su asistencia y quién no ha respondido aún. Esto te ayuda a gestionar la lista de invitados de manera efectiva.`,
          },
          {
            span: `\n\n¿Kolibird ofrece soporte técnico para los usuarios?\n\n`,
          },
          {
            text: `Sí, Kolibird cuenta con un equipo de soporte técnico dedicado que está disponible para brindarte asistencia y resolver cualquier duda o problema que puedas tener. Estamos aquí para ayudarte en cada paso del camino.\n\n¡No pierdas la oportunidad de sacar todo el partido a nuestro `,
          },
          {
            span: `servicio de invitaciones digitales para organizadores de eventos! `,
          },
          {
            text: `Obtén acceso a Kolibird y descubre todas las ventajas que nuestra herramienta puede ofrecerte. `,
          },
          { button: "Contactar" },
        ],
      },
    ],
    button: "Contactar",
  },
  en: {
    title: "Digital invitations for company events",
    text: [
      {
        h2: `Would you like to send the invitations for your company event with Kolibird?`,
        paragraph: [
          {
            text: `We are ready for it! We offer`,
          },
          {
            span: ` special rates and conditions to companies, event management agencies and wedding planners `,
          },
          {
            text: `so that you can send the invitations for your events or that you can offer the use of our tool to your clients. \n\n`,
          },
          {
            text: `You can use it directly or ask us for help to manage aspects ranging from `,
          },
          {
            span: `the design of invitations for events `,
          },
          {
            text: `or save the dates to the creation of a personalised tool where your brand or that of your clients will be the protagonist. \n\n `,
          },
          {
            text: ` Write to us and we will contact you as soon as possible!  `,
          },
          { button: "Contact us" },
        ],
      },
      {
        h2: `Our strengths:`,
        paragraph: [],
      },
      {
        h3: `1. Customisation::`,
        paragraph: [
          {
            text: `You will be able to use one of our templates or start your design from scratch. `,
          },
          {
            span: `You can even upload your own photo or design `,
          },
          {
            text: `and finish editing it in our tool.`,
          },
        ],
      },
      {
        h3: `2. Import your contact list: `,
        paragraph: [
          {
            text: `Quickly import the list of contacts you want to send your invitation to. You can download our excel template and complete it with the e-mails and names of the attendees. We will validate that all the email addresses are correct.`,
          },
        ],
      },
      {
        h3: `3. Save The Date: `,
        paragraph: [
          {
            text: `You can send, prior to the invitation, a Save The Date so that your guests save the date of the wedding and do not forget it while you work on the organization of the event.`,
          },
        ],
      },
      {
        h3: `4. Track: `,
        paragraph: [
          {
            text: `Track in `,
          },
          {
            span: `real time  `,
          },
          {
            text: `which attendees have received the invitation, who has opened it, who has replied and who has not and, of course, who is attending the event and who is not.`,
          },
        ],
      },
      {
        h3: `5. Surveys:`,
        paragraph: [
          {
            text: `You can ask questions to the attendees in the invitation itself, such as: "Will you need to use our bus service, do you have any allergies or intolerances, do you have any allergies or intolerances? Collect all this information and download it in a document that allows you to optimise your time.`,
          },
        ],
      },
      {
        h3: `6.  Reminders: `,
        paragraph: [
          {
            text: `You can set `,
          },
          {
            span: `automatic `,
          },
          {
            text: `reminders for those guests who have not opened or have not replied to the invitation.`,
          },
        ],
      },
      {
        h3: `7. Access: `,
        paragraph: [
          {
            text: ` Attendees will be able to access the event by means of a `,
          },
          {
            span: `nominal QR  `,
          },
          {
            text: `that they will receive prior to the event and that will be validated by means of an iOS and Android app. \n\n `,
          },
          {
            text: `¡Write us and we will contact you as soon as possible!`,
          },
          { button: "Contact us" },
        ],
      },
      {
        h3: "Who has put their trust in Kolibird?",
        paragraph: [
          { text: "These are some of the clients we have worked with so far." },
          { slider: " " },
        ],
      },
      {
        h3: `Digital invitations for weddings planners and event agencies`,
        paragraph: [
          {
            text: `In the event industry, first impressions count and invitations play a crucial role in creating expectations and emotions in guests. \n\nWith the advancement of technology, `,
          },
          {
            span: `digital invitations `,
          },
          {
            text: `have become a modern and efficient alternative for wedding planners and event agencies. \n\nDo you want to know how Kolibird, through a specialised tool, can help professionals in the sector to offer this service to their clients?
            `,
          },
          { button: "Contact Us" },
        ],
      },
      {
        h2: `Frequently Asked Questions (FAQs)`,
        paragraph: [
          {
            span: `What are the costs of using Kolibird as a wedding planner or event agency? \n\n`,
          },
          {
            text: `The costs of using Kolibird as a wedding planner vary according to the plan and the specific needs. We invite you to contact us for more information on rates and conditions.`,
          },

          {
            span: `\n\nCan I use my own branding on digital invitations?\n\n`,
          },
          {
            text: `Yes, Kolibird offers customization options that allow you to include your own branding on the digital invitations. This helps to maintain the coherence of the visual identity of your business.`,
          },
          {
            span: `\n\nHow can I keep track of RSVPs?\n\n`,
          },
          {
            text: `Advanced RSVP tracking features are available. You will be able to see who has opened the invitation, who has confirmed their attendance and who has not responded yet. This helps you manage the guest list effectively.`,
          },
          {
            span: `\n\nDoes Kolibird offer technical support for users?\n\n`,
          },
          {
            text: `Yes, Kolibird has a dedicated technical support team that is available to assist you and solve any questions or problems you may have. We are here to help you every step of the way.\n\nDon't miss your chance to get the most out of our `,
          },
          {
            eng: `digital invitations service for event organizers! `,
          },
          {
            text: `Get access to Kolibird and discover all the advantages that our tool can offer you. `,
          },
          { button: "Contact Us" },
        ],
      },
    ],
    button: "Contact Us",
  },
};
