import * as React from "react";
import Button from "./layout/Button";
import { translations } from "../locales/links";
import { settingsBusinessLogos } from "../constants/carrouselConfig";
import BusinessSlider from "./layout/businessSlider";
const EventsTypes = ({
  background,
  title,
  text,
  textButton,
  language,
  isBusiness = false,
}) => {
  return (
    <div className="eventsTypes-container">
      <img
        className="eventsTypes-img"
        src={background}
        style={{ shapeOutside: `url(${background})` }}
      />
      <div className="eventsTypes">
        <h1 className="eventsTypes-title">{title}</h1>

        {text.map((title, key) => {
          return (
            <div key={key}>
              {title.h2 && <h2>{title.h2}</h2>}
              {title.h3 && <h3>{title.h3}</h3>}

              <p className="eventsTypes-text">
                {title.paragraph.map((element, key) => {
                  console.log(element);
                  if (element.text) {
                    return element.text;
                  } else if (element.span) {
                    return <b key={key}>{element.span}</b>;
                  } else if (element.button) {
                    return (
                      <div className="eventsTypes-button">
                        <a
                          href={
                            isBusiness
                              ? `${process.env.GATSBY_ACCESS_URL}contact-us`
                              : translations[language]?.start[1]
                          }
                        >
                          <Button type="primary" text={element.button}></Button>
                        </a>
                      </div>
                    );
                  } else if (element.slider) {
                    return (
                      <div className="div-businessLogo">
                        <BusinessSlider config={settingsBusinessLogos} />
                      </div>
                    );
                  }
                })}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default EventsTypes;
